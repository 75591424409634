import gql from "graphql-tag";

export const OPERATOR_WORKS_QUERY = gql`
  query {
    operatorWorks {
      edges {
        node {
          id
          name
          equipmentsoperatorworksSet {
            edges {
              node {
                id
                equipment {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
