import gql from "graphql-tag";

export const CURRENT_FARMER_QUERY = gql`
  query($id: ID!) {
    currentFarmer(farmersoperators_OperatorId: $id) {
      edges {
        node {
          id
          name
          fieldsSet {
            edges {
              node {
                id
                name
                prefecture
                city
                address
                lat
                lon
                sectionsSet {
                  edges {
                    node {
                      id
                      name
                      rowsSet {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MY_FARMER_QUERY = gql`
  query {
    me {
      username
      id
      farmersoperatorsSet {
        edges {
          node {
            id
            farmer {
              id
              name
            }
          }
        }
      }
    }
  }
`;
