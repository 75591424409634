<template>
  <div>
    <b-modal ref="dialog" @hidden="close" hide-footer title="編集">
      <div>
        <b-table id="equipment" responsive="sm" :fields="fields" :items="this.equipments">
          <template v-slot:cell(name)="data">
            <td>{{ data.item.equipmentName }}</td>
          </template>
          <template v-slot:cell(delete)="data">
            <b-button
              type="submit"
              size="sm"
              variant="danger"
              class="icon-trash"
              v-on:click="deleteEquipment(data.item.relationId)"
            ></b-button>
          </template>
        </b-table>
      </div>
      <equipment-form
        :propValidMsg="validMsg"
        :propValidation="validation"
        @getEquipment="setEquipment"
      ></equipment-form>
      <b-row>
        <b-col class="text-right">
          <b-button variant="outline-primary" v-on:click="addEquipment">追加</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { EQUIPMENTS_OPERATOR_WORKS_QUERY } from "../../components/equipment-operator-work-query";
import {
  CREATE_EQUIPMENTS_OPERATOR_WORKS,
  DELETE_EQUIPMENTS_OPERATOR_WORKS
} from "../../components/equipment-operator-work-mutation";
import EquipmentForm from "./form/EquipmentForm";

export default {
  components: {
    EquipmentForm
  },
  props: {
    propOperatorWork: {
      default: null
    }
  },
  watch: {
    propOperatorWork: function() {
      this.init();
    }
  },
  data() {
    return {
      operatorWork: null,
      equipments: [],
      equipment: null,
      equipmentId: null,
      fields: [
        { key: "name", label: "機材" },
        { key: "delete", label: "削除" }
      ],
      validation: null,
      validMsg: ""
    };
  },
  methods: {
    init: function() {
      // 初期化
      this.operatorWorkId = this.propOperatorWork.id;
      this.equipments = [];
      this.validation = null;
      this.validMsg = "";
      let equipmentsSets = this.propOperatorWork.equipmentsoperatorworksSet
        .edges;
      equipmentsSets.forEach(element => {
        this.equipmentsSet(element.node);
      });
    },
    equipmentsSet: function(val) {
      let equipmentSet = {};
      equipmentSet.relationId = val.id;
      equipmentSet.equipmentId = val.equipment.id;
      equipmentSet.equipmentName = val.equipment.name;
      this.equipments.push(equipmentSet);
    },
    // --------------------------------
    // modalを閉じる
    // --------------------------------
    close: function() {
      this.operatorWork = null;
      this.init();
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    setEquipment: function(equipment) {
      // 初期化
      this.validation = null;
      this.validMsg = "";
      this.equipmentId = equipment.id;
    },
    equipmentDuplicateValid: function() {
      for (let index in this.equipments) {
        if (this.equipments[index].equipmentId == this.equipmentId) {
          this.validation = false;
          this.validMsg = "すでに登録済みです";
        }
      }
    },
    // --------------------------------
    // 使用機材を追加
    // --------------------------------
    addEquipment: function() {
      this.equipmentDuplicateValid();
      if (this.validation != false) {
        this.$apollo
          .mutate({
            mutation: CREATE_EQUIPMENTS_OPERATOR_WORKS,
            variables: {
              input: {
                operatorWorkId: this.operatorWorkId,
                equipmentId: this.equipmentId
              }
            }
          })
          .then(result => {
            this.equipmentsSet(
              result.data.createEquipmentOperatorWork.equipmentOperatorWork
            );
            this.$emit("refetch");
          });
      }
    },
    // --------------------------------
    // 使用機材を削除
    // --------------------------------
    deleteEquipment: function(equipmentsOperatorWorkId) {
      this.$apollo
        .mutate({
          mutation: DELETE_EQUIPMENTS_OPERATOR_WORKS,
          variables: {
            id: equipmentsOperatorWorkId
          }
        })
        .then(result => {
          for (let index in this.equipments) {
            if (
              result.data.deleteEquipmentOperatorWork.equipmentOperatorWork
                .id == this.equipments[index].relationId
            ) {
              this.equipments.splice(index, 1);
            }
          }
          this.$emit("refetch");
        });
    }
  }
};
</script>
