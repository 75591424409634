import gql from "graphql-tag";

export const EQUIPMENTS_OPERATOR_WORKS_QUERY = gql`
  query($id: ID!) {
    operatorWorks(id: $id) {
      edges {
        node {
          equipmentsoperatorworksSet {
            edges {
              node {
                id
                equipment {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
