import gql from "graphql-tag";

export const CREATE_OPERATOR_WORK = gql`
  mutation createOperatorWork($input: OperatorWorkInput!) {
    createOperatorWork(input: $input) {
      operatorWork {
        id
        name
      }
    }
  }
`;

export const UPDATE_OPERATOR_WORK = gql`
  mutation updateOperatorWork($id: ID!, $input: OperatorWorkInput!) {
    updateOperatorWork(id: $id, input: $input) {
      operatorWork {
        id
        name
      }
    }
  }
`;

export const DELETE_OPERATOR_WORK = gql`
  mutation deleteOperatorWork($id: ID) {
    deleteOperatorWork(id: $id) {
      operatorWork {
        id
        name
      }
    }
  }
`;
