import gql from "graphql-tag";

export const CREATE_EQUIPMENTS_OPERATOR_WORKS = gql`
  mutation createEquipmentOperatorWork($input: EquipmentOperatorWorkInput!) {
    createEquipmentOperatorWork(input: $input) {
      equipmentOperatorWork {
        id
        equipment {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_EQUIPMENTS_OPERATOR_WORKS = gql`
  mutation deleteEquipmentOperatorWork($id: ID!) {
    deleteEquipmentOperatorWork(id: $id) {
      equipmentOperatorWork {
        id
        equipment {
          id
          name
        }
      }
    }
  }
`;
