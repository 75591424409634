<template>
  <div>
    <b-form-group>
      <label>
        <b>機材追加</b>
      </label>
      <b-form-select v-model="equipment" v-on:change="selectedEquipment" :state="propValidation">
        <option :value="null" disabled>-- 機材選択 --</option>
        <option
          v-for="item in equipments.edges"
          :key="item.node.id"
          :value="item.node"
        >{{ item.node.name }}</option>
      </b-form-select>
      <b-form-invalid-feedback :state="propValidation">
        {{
        propValidMsg
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { EQUIPMENTS_QUERY } from "../../../components/equipment-query";
export default {
  props: {
    propValidMsg: {
      default: null
    },
    propValidation: {
      default: null
    }
  },
  data() {
    return {
      equipment: null,
      equipments: []
    };
  },
  apollo: {
    equipments: {
      query: EQUIPMENTS_QUERY,
      result({ data, loading }) {},
      error(error) {
        console.error("equipments", error);
      }
    }
  },
  methods: {
    selectedEquipment: function() {
      this.$emit("getEquipment", this.equipment);
    }
  }
};
</script>
