<template>
  <div>
    <b-collapse id="collapse" v-model="visible">
      <b-form-group inline>
        <b-row>
          <b-col cols="7">
            <b-form-input placeholder="作業内容名" v-model="form.name"></b-form-input>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary" v-on:click="createOperatorWork">作成</b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-collapse>
    <div class="mt-3">
      <b-button
        :class="visible ? null : 'collapsed'"
        :aria-expanded="visible ? 'true' : 'false'"
        @click="visible = !visible"
        aria-controls="collapse"
        class="icon-plus"
        variant="outline-primary"
      ></b-button>
    </div>
  </div>
</template>

<script>
import { CREATE_OPERATOR_WORK } from "../../components/operator-work-mutation";
export default {
  props: {
    propFarmerId: {
      default: null
    }
  },
  data() {
    return {
      form: {
        name: "",
        farmerId: this.propFarmerId
      },
      visible: false
    };
  },
  watch: {
    propFarmerId: function() {
      this.form.farmerId = this.propFarmerId;
    }
  },
  methods: {
    init: function() {
      this.form.name = "";
      this.visible = false;
    },
    createOperatorWork: function() {
      if (this.form.name != "" && this.form.name != null) {
        this.$apollo
          .mutate({
            mutation: CREATE_OPERATOR_WORK,
            variables: {
              input: this.form
            }
          })
          .then(data => {
            this.init();
            this.$emit("refetch");
          });
      }
    }
  }
};
</script>
<style>
button.btn.icon-plus.btn-outline-primary {
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.add-operator-work {
  padding-left: 35px;
}
</style>
