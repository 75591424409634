<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h3>作業内容マスタ 編集</h3>
        </b-col>
      </b-row>
      <operator-work-maintenance :propFarmerId="farmerId"></operator-work-maintenance>
    </b-card>
  </div>
</template>

<script>
import OperatorWorkMaintenance from "../components/OperatorWorkMaintenance";
import { MY_FARMER_QUERY } from "../../components/current-farmer-query";

const DEFAULT_INDEX = 0;

export default {
  components: {
    OperatorWorkMaintenance
  },
  data() {
    return {
      farmerId: null
    };
  },
  beforeCreate() {
    this.$apollo
      .query({
        query: MY_FARMER_QUERY
      })
      .then(result => {
        this.farmerId =
          result.data.me.farmersoperatorsSet.edges[
            DEFAULT_INDEX
          ].node.farmer.id;
      });
  }
};
</script>
