<template>
  <div>
    <operator-work-update-modal-form
      :propOperatorWork="operatorWork"
      ref="operatorWorkUpdateModalForm"
      @refetch="refetch"
    />
    <operator-work-delete-modal-form
      :propOperatorWorkId="operatorWorkId"
      ref="operatorWorkDeleteModalForm"
      @refetch="refetch"
    />
    <div>
      <b-table id="operatorWork" responsive="sm" :fields="fields" :items="this.operatorWorks.edges">
        <template v-slot:cell(name)="data">
          <b-td>{{ data.item.node.name }}</b-td>
        </template>
        <template v-slot:cell(update)="data">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="icon-pencil"
            v-bind="operatorWork"
            v-on:click="showUpdateDialog(data.item.node)"
          ></b-button>
        </template>
        <template v-slot:cell(delete)="data">
          <b-button
            type="submit"
            size="sm"
            variant="danger"
            class="icon-trash"
            v-bind="operatorWork"
            v-on:click="showDeleteDialog(data.item.node.id)"
          ></b-button>
        </template>
      </b-table>
      <operator-work-create-form :propFarmerId="farmerId" @refetch="refetch"></operator-work-create-form>
    </div>
  </div>
</template>

<script>
import { OPERATOR_WORKS_QUERY } from "../../components/operator-work-query";
import OperatorWorkCreateForm from "./OperatorWorkCreateForm";
import OperatorWorkUpdateModalForm from "./OperatorWorkUpdateModalForm";
import OperatorWorkDeleteModalForm from "./OperatorWorkDeleteModalForm";

export default {
  components: {
    OperatorWorkCreateForm,
    OperatorWorkUpdateModalForm,
    OperatorWorkDeleteModalForm
  },
  props: ["propFarmerId"],
  data() {
    return {
      farmerId: this.propFarmerId,
      operatorWorks: [],
      operatorWork: null,
      operatorWorkId: null,
      fields: [
        { key: "name", label: "作業内容" },
        { key: "update", label: "編集" },
        { key: "delete", label: "削除" }
      ]
    };
  },
  watch: {
    propFarmerId: function() {
      this.farmerId = this.propFarmerId;
    }
  },
  methods: {
    // --------------------------------
    // 編集 modalを表示
    // --------------------------------
    showUpdateDialog: function(operatorWork) {
      this.operatorWork = operatorWork;
      this.$refs.operatorWorkUpdateModalForm.showDialog();
    },
    // --------------------------------
    // アーカイブ modalを表示
    // --------------------------------
    showDeleteDialog: function(operatorWorkId) {
      this.operatorWorkId = operatorWorkId;
      this.$refs.operatorWorkDeleteModalForm.showDialog();
    },
    // --------------------------------
    // 再取得
    // --------------------------------
    refetch: function() {
      this.$apollo.queries.operatorWorks.refetch();
    },
    getData: function() {
      this.$apollo.addSmartQuery("operatorWorks", {
        query: OPERATOR_WORKS_QUERY,
        variables: {
          farmerId: this.farmerId
        },
        result({ data }) {
          if (data) {
            this.operatorWorks = data.operatorWorks;
          }
        }
      });
    }
  },
  mounted() {
    this.farmerId = this.propFarmerId;
    this.getData();
  }
};
</script>
<style>
.table td {
  padding-bottom: 8px;
}
.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 0.5rem;
}
</style>
